// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-post-js": () => import("./../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-tag-tag-js": () => import("./../src/templates/tag/tag.js" /* webpackChunkName: "component---src-templates-tag-tag-js" */),
  "component---src-templates-archive-archive-js": () => import("./../src/templates/archive/archive.js" /* webpackChunkName: "component---src-templates-archive-archive-js" */),
  "component---src-pages-404-index-js": () => import("./../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-success-index-js": () => import("./../src/pages/success/index.js" /* webpackChunkName: "component---src-pages-success-index-js" */),
  "component---src-pages-tag-index-js": () => import("./../src/pages/tag/index.js" /* webpackChunkName: "component---src-pages-tag-index-js" */)
}

